import React from "react"
import "./styles.scss"

import Section from "../section"
import SectionSubtitle from "../sectionSubtitle"
import Text from "../text"
import ChevronIcon from "../../images/chevron-right.svg"
import arrowIcon from "../../images/arrow-up-right.svg"

const About = ({ color }) => (
  <div id="about">
    <Section title="chi sono" color="transparent">
      <div className="fix-content-width">
        <div className="paddings-lr">
          <div className="about-flex-fix">
            <div
              data-aos="custom-fade"
              data-aos-duration="1000"
              data-aos-delay="100"
              className="rectangle img-one"
            ></div>
            <div className="about-text-fix">
              <p
                data-aos="custom-fade"
                data-aos-duration="1000"
                data-aos-offset="100"
                className="text-sm-dark about-text"
              >
                Stefano Maffina, ho conseguito la laurea magistrale nel 2016
                presso l’Università Cattolica del Sacro Cuore di Brescia.
              </p>
              <p
                data-aos="custom-fade"
                data-aos-duration="1000"
                className="text-sm-dark about-text"
              >
                Nel 2018 mi sono abilitato all'esercizio della professione di
                psicologo presso l'Ordine degli psicologi della Lombardia (n.
                20469),
              </p>
              <p
                data-aos="custom-fade"
                data-aos-duration="1000"
                className="text-sm-dark about-text"
              >
                Mi sono specializzato come Psicoterapeuta cognitivo
                comportamentale presso la scuola “AcaBS-Accademy of behavioural
                science” di Brescia.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  </div>
)

export default About
