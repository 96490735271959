import React from "react"
import Collapsible from "react-collapsible"
import "./styles.scss"

import Section from "../section"
import SectionSubtitle from "../sectionSubtitle"

const faq = ({ color }) => (
  <div>
    <Section color="transparent" title="FAQ">
      <div>
        <div style={{ marginTop: "48px" }}>
          <div className="st-container fix-content-width">
            <div
              className="desktop-fix"
              data-aos="custom-fade"
              data-aos-duration="1000"
            >
              <Collapsible
                className="acc-trigger"
                openedClassName="acc-trigger-opened"
                trigger="Quando puo' essere utile rivolgersi ad uno psicologo?"
              >
                <p>
                  Ci sono diversi motivi per cui una persona decide di
                  rivolgersi ad uno psicologo. Una consulenza o un supporto
                  psicologico possono essere utili per superare una crisi
                  temporanea, per favorire una crescita interiore personale, per
                  orientare nelle scelte, per raggiungere una maggiore e
                  migliore consapevolezza di sé, degli altri e del proprio
                  contesto familiare, sociale, lavorativo o scolastico. Un
                  qualsiasi mutamento o evento nella propria esistenza può
                  rivelarsi di difficile elaborazione e superamento. Ed è qui
                  che la consulenza psicologica può risultare un benefico
                  supporto ed un aiuto positivo, guidando la persona attraverso
                  il disagio interno fino alla sua attenuazione e scomparsa.
                  Rivolgersi allo psicologo può essere utile per creare uno
                  spazio diverso da quelli abituali della vita di tutti i
                  giorni, dove confidarsi e confrontarsi e può diventare un
                  nuovo punto di riferimento.
                </p>
              </Collapsible>
            </div>
            <div
              className="desktop-fix"
              data-aos="custom-fade"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <Collapsible
                className="acc-trigger"
                openedClassName="acc-trigger-opened"
                trigger="Se chiedo aiuto significa che sono debole e non posso farcela da solo?"
              >
                <p>
                  È capitato a tutti di attraversare un momento difficile in cui
                  si ha la sensazione di non farcela. In questi momenti chiedere
                  aiuto, oltre ad essere un atto di responsabilità verso sé
                  stessi, può aiutare ad attutire le diverse emozioni negative
                  che si possono sperimentare in momenti bui della propria vita,
                  come vergogna, insicurezza e sensi di colpa. Consultare uno
                  psicologo quando si vive un disagio o si riconosce di avere
                  problema è un atto di coraggio. Accettare l'aiuto di un
                  professionista è segno di grande maturità e di determinazione
                  verso un cambiamento costruttivo, è il primo passo per poter
                  stare meglio. Come trovare il modo di sentirsi forti quando
                  chiediamo aiuto? Crea dentro di te uno spazio in cui poterti
                  aprire all’accettazione dell’aver bisogno di aiuto in modo
                  tale da poterlo ricevere e ottenere: non otterrai ciò che vuoi
                  se non chiedi. Cosa te lo impedisce? Se il pensiero di
                  chiedere aiuto ti fa sentire a disagio, pensa a cosa vuoi
                  ottenere veramente, potresti concretamente conseguire una
                  prospettiva diversa e ridurre i problemi. Chiedere un supporto
                  giusto al momento giusto porta ad un risultato migliore.
                </p>
              </Collapsible>
            </div>
            <div
              className="desktop-fix"
              data-aos="custom-fade"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <Collapsible
                className="acc-trigger"
                openedClassName="acc-trigger-opened"
                trigger="Perche' proprio lo psicologo e non altre persone?"
              >
                <p>
                  La presenza di persone care, di cui ci fidiamo e che ci
                  comprendono, è di estremo conforto per ognuno di noi. Parlare
                  con amici o familiari, infatti, può alleggerire dubbi o
                  aiutare a placare il malessere di alcuni momenti, ma
                  difficilmente potrà aiutarci se ci sono problemi più radicati.
                  Lo psicologo non fornisce consigli, è un professionista
                  formato che aiuta il paziente a sviluppare un punto di vista
                  diverso, fornendogli strumenti grazie ai quali è possibile
                  attuare un cambiamento, permettendogli di costruire una
                  visione del mondo e di sé più incline con la realtà, con lo
                  scopo di raggiungere uno stato maggior benessere. Consente di
                  divenire consapevoli dei propri schemi di pensiero e
                  comportamento e di comprendere come si siano sviluppati nel
                  corso della nostra esistenza. Lo psicologo accompagna il
                  paziente alla radice del problema e insieme a lui lo affronta.
                </p>
              </Collapsible>
            </div>
            <div
              className="desktop-fix"
              data-aos="custom-fade"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <Collapsible
                className="acc-trigger"
                openedClassName="acc-trigger-opened"
                trigger="Il cambiamento e' davvero possibile?"
              >
                <p>
                  La terapia richiede molto impegno e quindi bisogna essere
                  motivati per raggiungere i risultati concordati. Attraverso
                  una collaborazione attiva con il terapeuta all’interno dello
                  studio, il paziente sarà in grado di applicare nella
                  quotidianità le tecniche e gli strumenti fornitigli durante le
                  sedute, per raggiungere i propri obiettivi di vita. Con la
                  terapia cognitivo-comportamentale il paziente viene guidato
                  nella modificazione dei processi di pensiero disfunzionali,
                  che mantengono la sofferenza, proponendo modalità di pensiero
                  più adeguate e coerenti con la realtà. Il paziente svilupperà
                  strategie comportamentali funzionali rispetto agli obiettivi
                  che vengono definiti. Il cambiamento è possibile grazie alla
                  motivazione e al lavoro su di sé.
                </p>
              </Collapsible>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </Section>
  </div>
)

export default faq
