import React from "react"

import Layout from "../components/layout"
import Hero from "../components/hero"
import About from "../components/about"
import MyWork from "../components/myWork"
import Method from "../components/method"
import Faq from "../components/faq"
import Contact from "../components/contact"
import Footer from "../components/footer"
import Points from "../components/points"
import Illnesses from "../components/illnesses"
import Seo from "../components/seo"
import BlogList from "../components/blogList"

import "../styles/animations.scss"
import "./index.scss"

const IndexPage = () => (
  <Layout>
    <Seo
      lang="it"
      title="Stefano Maffina | Psicologo clinico | Gardone Val Trompia"
      description="Stefano Maffina, psicologo clinico a Gardone Val Trompia, BS"
    />
    <Hero />
    <About />
    <MyWork />
    <Illnesses />
    <Method />
    <Points />
    <Faq />
    <BlogList />
    <Contact />
    <Footer />
  </Layout>
)

export default IndexPage
