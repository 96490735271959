import React, { useState, useEffect } from "react"
import "./styles.scss"
import NavBar from "../navBar"
import { window, document } from "browser-monads"

import SectionSubtitle from "../sectionSubtitle"
import { loadableP5 as P5Wrapper } from "../loadable"
import Sketch from "../sketch"
import sectionSubtitle from "../sectionSubtitle"

const Hero = () => {
  const [scrolled, setScrolled] = useState(false)
  const [color, setColor] = useState("white")

  useEffect(() => {
    const handleScroll = () => {
      let topTen = (document.body.offsetHeight / 100) * 5
      let bottomTen = (document.body.offsetHeight / 100) * 80
      if (window.scrollY < topTen) {
        setColor("white")
      } else if (window.scrollY < bottomTen && window.scrollY > topTen) {
        setColor("white")
      } else if (window.scrollY > bottomTen) {
        setColor("white")
      }
      const isScrolled = window.scrollY > 100
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled)
      }
    }

    document.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener("scroll", handleScroll)
    }
  }, [scrolled])

  return (
    <div
      className="container fix-content-width"
      style={{
        transition: "all 500ms ease",
      }}
    >
      <NavBar />
      <div
        style={{
          opacity: scrolled ? "1" : "1",
          transition: "all 500ms ease",
          zIndex: "-1",
        }}
      >
        <div
          style={{
            position: "fixed",
            top: "-50px",
            left: "0",
            opacity: "1",
            transition: "all 1s ease",
            backgroundColor: color,
          }}
        >
          <div
            style={{
              opacity: scrolled ? "0.1" : "0.8",
              transition: "all 1s ease",
              marginBottom: "10vh",
            }}
          >
            <P5Wrapper sketch={Sketch} />
          </div>
        </div>
      </div>
      <div className="hero-header">
        <div className="hero-title">
          <h2
            style={{
              transition: "all 1s ease",
              fontSize: "3rem",
              marginBottom: "1vh",
            }}
          >
            <b>
              Dott. <br className="hid-fix" /> Stefano Maffina
            </b>
          </h2>
          <h2
            style={{
              transition: "all 1s ease",
              fontSize: "1.8rem",
            }}
          >
            Psicologo, Psicoterapeuta cognitivo-comportamentale
          </h2>
        </div>
      </div>
      <div
        className="fix-slider paddings-lr"
        style={{
          transition: "all 1s ease",
        }}
      >
        <span className="text-sm-dark hero-text">
          Svolgo la libera professione presso il mio studio privato di{" "}
          <span style={{ color: "black", fontWeight: "bold" }}>
            Gardone Val Trompia
          </span>{" "}
          dove seguo adulti e adolescenti.
        </span>
        <div></div>
      </div>
    </div>
  )
}

export default Hero
