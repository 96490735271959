import React from "react"
import Collapsible from "react-collapsible"
import "./styles.scss"

import Section from "../section"

const method = ({ color }) => (
  <div>
    <Section color="transparent" title="la terapia">
      <div>
        <div className="section-subtitle">
          <h2
            data-aos="custom-fade"
            data-aos-duration="1000"
            style={{ color: "#121212" }}
          >
            <b>La terapia</b> in sei punti.
          </h2>
        </div>
        <div style={{ marginTop: "48px" }}>
          <div className="st-container fix-content-width">
            <div
              className="desktop-fix"
              data-aos="custom-fade"
              data-aos-duration="1000"
            >
              <Collapsible
                className="acc-trigger"
                openedClassName="acc-trigger-opened"
                trigger="1. Pratica e concreta"
              >
                <p>
                  Le sedute della terapia sono{" "}
                  <i style={{ color: "#121212" }}>strutturate</i> e mirano a
                  fornire strumenti e strategie efficaci e{" "}
                  <i style={{ color: "#121212" }}>concrete</i> per far fronte
                  alle problematiche che affliggono la vita paziente.
                </p>
              </Collapsible>
            </div>
            <div
              className="desktop-fix"
              data-aos="custom-fade"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <Collapsible
                className="acc-trigger"
                openedClassName="acc-trigger-opened"
                trigger="2. Pedagogica"
              >
                <p>
                  La terapia mira ad{" "}
                  <i style={{ color: "#121212" }}>insegnare</i> al paziente ad
                  essere il terapeuta di sé stesso, concentrandosi sulla
                  prevenzione delle ricadute e fornendo al paziente gli
                  strumenti per allenarsi fuori dallo studio del terapeuta.
                </p>
              </Collapsible>
            </div>
            <div
              className="desktop-fix"
              data-aos="custom-fade"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <Collapsible
                className="acc-trigger"
                openedClassName="acc-trigger-opened"
                trigger="3. Centrata sul qui ed ora"
              >
                <p>
                  Il trattamento, nella maggior parte dei casi, comporta una
                  forte concentrazione sui problemi attuali e sulle specifiche
                  situazioni dolorose. Iniziando con un esame dei problemi nel{" "}
                  <i style={{ color: "#121212" }}>qui ed ora</i>, l’attenzione
                  della terapia può spostarsi sul passato, andando ad esaminare
                  assieme tutte le componenti di vita.
                </p>
              </Collapsible>
            </div>
            <div
              className="desktop-fix"
              data-aos="custom-fade"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <Collapsible
                className="acc-trigger"
                openedClassName="acc-trigger-opened"
                trigger="4. A breve termine"
              >
                <p>
                  La terapia mira ad essere{" "}
                  <i style={{ color: "#121212" }}>limitata</i> nel tempo,
                  attraverso un lavoro di remissione del disturbo e di sollievo
                  dai sintomi invalidanti.
                </p>
              </Collapsible>
            </div>
            <div
              className="desktop-fix"
              data-aos="custom-fade"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              <Collapsible
                className="acc-trigger"
                openedClassName="acc-trigger-opened"
                trigger="5. Orientata allo scopo"
              >
                <p>
                  Il paziente, durante le prime sedute, elenca i problemi di cui
                  vuole trattare e assieme al terapeuta si fissano obiettivi
                  specifici, per avere una visione condivisa dello{" "}
                  <i style={{ color: "#121212" }}>scopo</i> della terapia.
                </p>
              </Collapsible>
            </div>
            <div
              className="desktop-fix"
              data-aos="custom-fade"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <Collapsible
                className="acc-trigger"
                openedClassName="acc-trigger-opened"
                trigger="6. Attiva e collaborativa"
              >
                <p>
                  La terapia è come un lavoro di gruppo, tra terapeuta e
                  paziente, i quali decidono{" "}
                  <i style={{ color: "#121212" }}>insieme</i> su cosa lavorare
                  in ogni seduta, quanto frequentemente incontrarsi e cosa il
                  paziente può fare da solo,{" "}
                  <i style={{ color: "#121212" }}>attivamente</i>, tra una
                  seduta e l’altra fuori dallo studio.
                </p>
              </Collapsible>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </Section>
  </div>
)

export default method
