import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"

import "../singlepage.scss"
import Section from "../section"

const BlogList = ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
          allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
          ) {
            nodes {
              excerpt
              fields {
                slug
              }
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                description
              }
            }
          }
        }
      `}
      render={data => {
        const siteTitle = data.site.siteMetadata?.title || `Title`
        const posts = data.allMarkdownRemark.nodes
        if (posts.length === 0) {
          return <div></div>
        }
        return (
          <Section color="transparent" title="Blog">
            <div className="fix-content-width">
              <ol
                style={{ listStyle: `none`, margin: 0, paddingTop: "32px" }}
                className="paddings-lr postlist-container"
              >
                {posts.map(post => {
                  const title = post.frontmatter.title || post.fields.slug

                  return (
                    <li key={post.fields.slug} className="postlist-li">
                      <Link to={post.fields.slug} itemProp="url">
                        <article
                          className="post-list-item"
                          itemScope
                          itemType="http://schema.org/Article"
                        >
                          <header style={{ paddingBottom: "16px" }}>
                            <h2 style={{ fontSize: "20px" }}>
                              <span itemProp="headline">{title}</span>
                            </h2>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "200",
                                letterSpacing: "5%",
                                textTransform: "uppercase",
                              }}
                            >
                              {post.frontmatter.date}
                            </span>
                          </header>
                          <section>
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  post.frontmatter.description || post.excerpt,
                              }}
                              itemProp="description"
                              style={{ fontSize: "15px" }}
                            />
                          </section>
                        </article>
                      </Link>
                      <br />
                      <div
                        style={{
                          borderBottom: "0.5px solid rgba(0,0,0,0.1)",
                        }}
                      ></div>
                    </li>
                  )
                })}
              </ol>
            </div>
          </Section>
        )
      }}
    />
  )
}

export default BlogList
