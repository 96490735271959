export default function Sketch(p5) {
  let i
  let maxRep
  i = 0
  maxRep = 50

  p5.setup = () => {
    p5.createCanvas(p5.windowWidth, p5.windowHeight)
    p5.stroke("rgba(62,116,255, 0.1)")
    //p5.background(255);
    p5.noFill()
  }

  p5.draw = () => {
    //p5.background(255, 255, 255, 2)

    if (i < 7) {
      let t = p5.width * p5.noise(i + 15)
      let o = p5.width * p5.noise(i + 25)
      let n = p5.width * p5.noise(i + 35)
      let s = p5.width * p5.noise(i + 45)
      let d = p5.height * p5.noise(i + 55)
      let h = p5.height * p5.noise(i + 65)
      let r = p5.height * p5.noise(i + 75)
      let a = p5.height * p5.noise(i + 105)

      p5.bezier(t, d, o, h, n, r, s, a)
      i += 0.005
    }
  }
}
