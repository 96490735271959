import React from "react"
import "./styles.scss"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Link } from "gatsby"
import Section from "../section"
import SectionSubtitle from "../sectionSubtitle"
import Text from "../text"
import chevronIconBlue from "../../images/arrow-right.svg"
import chevronIconDark from "../../images/arrow-right_dark.svg"

const points = ({ color }) => (
  <div id="method">
    <Section color="transparent" title="Il metodo">
      <div className="fix-content-width">
        <div className="section-subtitle">
          <h2
            data-aos="custom-fade"
            data-aos-duration="1000"
            style={{ color: "#121212" }}
          >
            Utilizzo la{" "}
            <span style={{ fontWeight: 700, textDecoration: "italic" }}>
              terapia cognitivo-comportamentale
            </span>{" "}
          </h2>
        </div>
        {/* BEGIN CONTAINER FOR TEXT */}
        {/* FLEX CONTAINER RIGHT-LEFT */}
        <div className="text-content-container">
          {/* BORDER */}
          <div className="text-container-border"></div>
          <div className="text-content-flex">
            <div
              data-aos="custom-fade"
              data-aos-duration="1000"
              className="text-container"
            >
              <span
                style={{
                  color: "#666",
                  paddingBottom: "24px",
                  maxWidth: "60%",
                }}
              >
                E' considerato{" "}
                <b>
                  uno dei piu’ efficaci metodi di trattamento dei disturbi
                  psicologici.
                </b>{" "}
                Si basa sul presupposto che vi sia una correlazione tra
                pensieri, emozioni e comportamenti.
              </span>
            </div>
            <div
              data-aos="custom-border"
              data-aos-duration="1000"
              className="custom-text-border"
            ></div>
            <div className="flex-cta">
              <div
                data-aos="custom-fade"
                data-aos-duration="1000"
                className="paddings-lr link"
              >
                <Link to="#contact">
                  <span
                    style={{
                      paddingBottom: "4px",
                      fontWeight: "bold",
                      color: "#3e74ff",
                    }}
                  >
                    Prenota un appuntamento
                  </span>
                </Link>
                <img
                  style={{
                    marginBottom: "0",
                    marginLeft: "4px",
                    marginTop: "2px",
                    width: "20px",
                  }}
                  src={chevronIconBlue}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  </div>
)

export default points
