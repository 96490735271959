import React, { useState } from "react"
import { Link } from "gatsby"
import "./styles.scss"

import Section from "../section"
import chevronIcon from "../../images/chevron-right.svg"

const Illnesses = ({ color }) => {
  return (
    <Section color="#transparent" title="I disturbi comuni">
      <div style={{ height: "16px" }}></div>
      <div className="section-subtitle">
        <h2
          data-aos="custom-fade"
          data-aos-duration="1000"
          style={{ color: "#121212" }}
        >
          <b>Ti posso aiutare</b> ad affrontare:
        </h2>
      </div>
      <div className="card-container">
        <div className="add-border card anxiety">
          <Link to="/anxiety">
            <div className="card-content">
              <div className="card-title">
                <span className="info-sm-title">Disturbi d'ansia</span>
              </div>
              <div className="card-description paddings-lr text-sm-dark">
                <p>
                  Comprendono tutti quei disturbi che condividono
                  caratteristiche di paura e ansia eccessive verso qualcosa.
                </p>
              </div>
              <div className="card-showmore paddings-lr">
                <span
                  style={{
                    fontWeight: "bolder",
                    paddingBottom: "4px",
                  }}
                >
                  Scopri di piu'
                </span>
                <img src={chevronIcon} alt="" />
              </div>
            </div>
          </Link>
        </div>
        {/*
         * BEGIN NEXT # 2
         */}
        <div className="add-border card compulsive">
          <div></div>
          <Link to="/compulsive">
            <div className="card-content">
              <div className="card-title">
                <span className="info-sm-title">
                  Disturbo ossessivo-compulsivo
                </span>
              </div>
              <div className="card-description paddings-lr text-sm-dark">
                <p>
                  E' un disturbo d’ansia caratterizzato da pensieri intrusivi
                  che causano disagio, e comportamenti ripetitivi ...
                </p>
              </div>
              <div className="card-showmore paddings-lr">
                <span
                  style={{
                    fontWeight: "bolder",
                    paddingBottom: "4px",
                  }}
                >
                  Scopri di piu'
                </span>
                <img src={chevronIcon} alt="" />
              </div>
            </div>
          </Link>
        </div>
        {/*
         * BEGIN NEXT # 3
         */}
        <div className="add-border card ptsd">
          <div></div>
          <Link to="/ptsd">
            <div className="card-content">
              <div className="card-title">
                <span className="info-sm-title">
                  Disturbo post-traumatico da stress
                </span>
              </div>
              <div className="card-description paddings-lr text-sm-dark">
                <p>
                  E' un problema psicologico caratterizzato da un evento
                  traumatico.
                  <br />
                </p>
              </div>
              <div className="card-showmore paddings-lr">
                <span
                  style={{
                    fontWeight: "bolder",
                    paddingBottom: "4px",
                  }}
                >
                  Scopri di piu'
                </span>
                <img src={chevronIcon} alt="" />
              </div>
            </div>
          </Link>
        </div>
        {/*
         * BEGIN NEXT #4
         */}
        <div className="add-border card depression">
          <div></div>
          <Link to="/depression">
            <div className="card-content">
              <div className="card-title">
                <span className="info-sm-title">Depressione</span>
              </div>
              <div className="card-description paddings-lr text-sm-dark">
                <p>
                  E' caratterizzata da un costante stato di tristezza e da una
                  generale perdita di interesse ...
                </p>
              </div>
              <div className="card-showmore paddings-lr">
                <span
                  style={{
                    fontWeight: "bolder",
                    paddingBottom: "4px",
                  }}
                >
                  Scopri di piu'
                </span>
                <img src={chevronIcon} alt="" />
              </div>
            </div>
          </Link>
        </div>
        {/*
         * BEGIN NEXT #5
         */}
        <div className="add-border card eating">
          <div></div>
          <Link to="/eating">
            <div className="card-content">
              <div className="card-title">
                <span className="info-sm-title">
                  Disturbi del comportamento alimentare
                </span>
              </div>
              <div className="card-description paddings-lr text-sm-dark">
                <p>
                  Anoressia e bulimia sono tra i disturbi alimentari più
                  ampiamente trattati.
                </p>
              </div>
              <div className="card-showmore paddings-lr">
                <span
                  style={{
                    fontWeight: "bolder",
                    paddingBottom: "4px",
                  }}
                >
                  Scopri di piu'
                </span>
                <img src={chevronIcon} alt="" />
              </div>
            </div>
          </Link>
        </div>
        {/*
         * BEGIN NEXT LAST FUCK IT
         */}
        <div className="add-border card personality">
          <div></div>
          <Link to="/personality">
            <div className="card-content">
              <div className="card-title">
                <span className="info-sm-title">Disturbi di personalita'</span>
              </div>
              <div className="card-description paddings-lr text-sm-dark">
                <p>
                  i tratti di personalità diventano disturbi di personalità
                  quando i modelli di comportamento della persona ...
                </p>
              </div>
              <div className="card-showmore paddings-lr">
                <span
                  style={{
                    fontWeight: "bolder",
                    paddingBottom: "4px",
                  }}
                >
                  Scopri di piu'
                </span>
                <img src={chevronIcon} alt="" />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </Section>
  )
}

export default Illnesses
